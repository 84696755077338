//центр и масштаб яндекс-карты
var mapCentre, mapZoom, short_name;

var __COMMON = function () {
    var elements = {
        body: $('body')
    };
    var _init = {
        commonFunctions: function () {
            functions.initHeaderMenuEvents();
            functions.initShowMore();
            functions.initBreadcrumbs();
            functions.initSlider();
            functions.initSliderNews();
            functions.initSliderWidth();
            functions.initOpenBlock();
            functions.initTabs();
            functions.initSpoiler();
            functions.initAnchor();
            functions.initAccord();
            functions.initWhatsAppLink();
            functions.initEventFormElements();
        },
        rootPage: function () {
            functions.initRootBanner();
        },
        content: function () {

        },
        contactsPage: function () {
            functions.switchMapToList();
            functions.initMap();
        },
        newsPage: function () {
            functions.newsAjax();
        },
        eventsPage: function () {
            functions.eventsFilter();
        },
        restaurantPage: function () {
            functions.restaurantTablesSlider();
            functions.initMap();
        },
        cookingPage: function () {
            // functions.initMap();
        },
        mapInit: function () {
            functions.initMap();
        },
        restaurantsPage: function () {
            functions.restautantsFunctions();
        },
        restaurantsMenuPage: function () {
            functions.openMoreMenu();
            functions.fixedRestaurantTabs();
            functions.restaurantMenuAnchor();
            functions.openDescription();
        },
        initSelect: function () {
            functions.initSelect();
        },
        deliveryPage: function () {
            functions.restaurantTablesSlider();
            functions.initToggleDelivery();
            functions.initSelectDelivery();
        },
        promoPage: function () {
            functions.initPromoTimer();
        },
        initTabs: function () {
            functions.fixedRestaurantTabs();
        },
        functions: function () {
            return functions;
        }

    };
    var functions;
    functions = {
        openDescription: function () {
            $('.restaurants-menu__item .open-form-block').on('click', function (e) {
                if (document.documentElement.clientWidth <= 750) {
                    _FORMS.openMenuModal(this);
                }
            });
        },
        restaurantMenuAnchor: function () {
            $('.restaurants-menu-nav__item a').on('click', function (e) {
                e.preventDefault();
                functions.jAnchorWithStep($(this), 120);
            })

            $('.j-anchor-step').on('click', function (e) {
                e.preventDefault();
                functions.jAnchorWithStep($(this), 90);
            })

            let restaurants_block_top = $('.restaurants-menu').offset().top;
            let menu_height = $('.restaurants-menu').height();
            let menu_navigation_blocks = $(document).find('.restaurants-menu-nav');

            let groups_blocks = $('.restaurants-menu').find('.restaurants-menu__block');

            //посчитать высоту блока и расстояние до него снизу-сверху
            //после вызвать функцию, где будет проверка на блоке ли скролл
            $(document).scroll(function (){
                $(groups_blocks).each(function () {
                    console.log($(this));
                    let group_top = $(this).offset().top;
                    let group_height = $(this).height();

                    checkActiveGroup(group_top, group_height, $(this))
                });
            })

            function checkActiveGroup(top, height, el) {
                $(document).scroll(function () {
                    if (window.pageYOffset >= top - 150 && window.pageYOffset <= top + height) {
                        let el_index = $(el).index();

                        $(menu_navigation_blocks).each(function () {
                            $(this).find('.restaurants-menu-nav__item').removeClass("-active");
                            $(this).find('.restaurants-menu-nav__item').eq(el_index).addClass("-active");
                        });
                    }
                });
            }

            /*let blocks_count = $('.restaurants-menu').find('.restaurants-menu__block').length;
            let block_height = menu_height / blocks_count;
            let new_set_to_count;
            let set_to_count;
            let scroll_count = window.pageYOffset - restaurants_block_top;
            $(document).scroll(function () {
                scroll_count = window.pageYOffset - restaurants_block_top;

                if(window.pageYOffset >= restaurants_block_top && window.pageYOffset <= restaurants_block_top + menu_height ) {
                    checkActiveMenuItem ();
                }
            })

            function checkActiveMenuItem () {
                new_set_to_count = Math.round(scroll_count  / block_height);
                if(set_to_count !== new_set_to_count) {
                    $(menu_navigation_blocks).each(function () {
                        $(this).find('.restaurants-menu-nav__item').removeClass("-active");
                        $(this).find('.restaurants-menu-nav__item').eq(new_set_to_count).addClass("-active");
                    })

                    set_to_count = new_set_to_count;
                }
            }*/

        },
        jAnchorWithStep: function jAnchorWithStep(e, step = 0) {
            let _href = $(e).attr("href");
            let scroll_to = $(`${_href}`)
            let scroll_top = $(scroll_to).offset().top - step;

            $("html, body").animate({scrollTop: scroll_top}, 800);
        },
        fixedRestaurantTabs: function () {
            let offset_top = $('.static-container').offset().top;
            console.log(offset_top);

            $(document).scroll(function () {
                if (offset_top <= window.pageYOffset) {
                    $('.fixed-container').addClass('-fixed');
                } else {
                    $('.fixed-container').removeClass('-fixed');
                }
            })
        },
        openMoreMenu: function () {
            let toggle_status = {
                open_block: $(".open-block-wrap"),
                button: $('.open-block-wrap .more-dish'),
                active: false,
                open: function (elem) {
                    $(elem).addClass('-show');
                    $(elem).next().show('easing');
                    toggle_status.active = true;
                },
                close: function () {
                    $(this.button).removeClass('-show');
                    $(this.button).next().hide('easing'); // скрываем его
                    toggle_status.active = false;
                }
            }

            $('.more-dish').on("click", function () {
                if ($(this).hasClass('-show')) {
                    toggle_status.close();
                    return;
                }
                if (toggle_status.active && !$(this).hasClass('-show')) {
                    toggle_status.close();
                    toggle_status.open($(this));
                } else {
                    toggle_status.open($(this));
                }


            });

            $(document).mouseup(function (e) { // событие клика по веб-документу
                if (toggle_status.active) {
                    var div = toggle_status.open_block; // тут указываем ID элемента
                    if (!div.is(e.target) // если клик был не по нашему блоку
                        && div.has(e.target).length === 0 // и не по его дочерним элементам
                        || $(e.target).hasClass('list-item') || $(e.target).parent().hasClass('list-item')) {
                        toggle_status.close();
                    }
                }

            });
        },
        initHeaderMenuEvents: function () {
            menuMain();

            //headerSearch();

            function menuMain() {
                var header = $('.header'),
                    body = elements.body,
                    menu = $('.j-menu'),
                    burger = $('.j-burger');

                menuContainerSize();
                $(window).resize(function () {
                    menuContainerSize();
                });
                burger.on('click', function () {
                    toggleMenu();
                });

                function toggleMenu() {
                    //открытие
                    if (!body.hasClass('-mobile-menu')) {
                        //предотвращение дергания контента в момент открытия меню
                        functions.functionBodyScrollFix();

                        toggleMenuBody();
                        toggleMenuInner();
                    }
                    //закрытие
                    else {
                        toggleMenuInner();
                        setTimeout(toggleMenuBody, 500);
                    }

                    //левая часть меню, которая красиво выезжает
                    function toggleMenuInner() {
                        functions.functionBodyScrollFix_toggle();
                        body.toggleClass('-mobile-menu');
                        header.toggleClass('-mobile-menu');
                    }

                    //основная часть меню
                    function toggleMenuBody() {
                        menu.toggleClass('-open');
                    }
                }

                function menuContainerSize() {
                    if (burger.length) {
                        var $element = $('.menu-latent_container');
                        var left = burger.offset().left;
                        var right = $(window).width() - left - burger.width();

                        $element.css('margin-right', right);

                        var margTop = parseInt($element.css('margin-top'));
                        var margBot = parseInt($element.css('margin-bottom'));
                        var height = $(window).height() - margTop - margBot;
                        $element.css('height', height);
                    }
                }

                menu.click(function () {
                    if ($(this).hasClass('-open'))
                        toggleMenu();//клик по правой, полупрозрачной части меню должен закрывать меню
                })
                    .children()
                    .click(function (e) {
                        e.stopPropagation();
                    });

                //second level
                if ($(window).width() <= 1024)
                    menuMainSub();

                function menuMainSub() {
                    $(".menu-item-parent > .link").click(function () {
                        var wrapper = $(this).closest('.menu-item-parent');
                        var sub = wrapper.find('.menu-sub');

                        //раскрытие
                        if (!wrapper.hasClass('-open')) {
                            wrapper.addClass('-open');
                            sub.slideDown();
                        }
                        //закрытие
                        else {
                            wrapper.removeClass('-open');
                            sub.slideUp();
                        }
                        return false;
                    });
                }
            }


            function headerSearch() {
                //opening
                $('.search-handle').click(function () {
                    $(this).closest('.search').addClass('-open');
                });
                //closing
                $(document).click(function (e) {
                    if ($(e.target).closest(".search").length) return;
                    $('.search').removeClass('-open');
                    e.stopPropagation();
                });
            }


            //высота для 100%-го блока
            const appHeight = () => {
                const doc = document.documentElement
                doc.style.setProperty('--app-height', `${window.innerHeight}px`)
            }
            appHeight();
        },
        initShowMore: function () {
            let buttons = $('.show-more-button');
            if (buttons.length) {
                $.each(buttons, function (key) {
                    let button = buttons.eq(key),
                        hiddenBlock = button.prev(),
                        textToHide = button.attr('data-hide');
                    if (textToHide !== undefined) {
                        let isHidden = true,
                            textBlock = button.find('.text'),
                            textToShow = textBlock.text().trim();
                        button.on('click', function (e) {
                            e.preventDefault();
                            if (isHidden) {
                                textBlock.text(textToHide);
                                hiddenBlock.slideDown();
                                isHidden = false;
                            } else {
                                textBlock.text(textToShow);
                                hiddenBlock.slideUp();
                                isHidden = true;
                            }
                        });
                    } else {
                        button.on('click', function (e) {
                            e.preventDefault();
                            button.css({
                                visibility: 'hidden',
                                pointerEvents: 'none'
                            });
                            hiddenBlock.slideDown();
                        });
                    }
                });
            }
        },
        initBreadcrumbs: function () {
            let items_count = $('.breadcrumbs .item').length;
            if (items_count) {
                tns({
                    container: '.tns_slider .tns_slider_wrap',
                    items: 5.5,
                    nav: false,
                    mouseDrag: true,
                    slideBy: 1,
                    swipeAngle: false,
                    autoplay: false,
                    autoWidth: true,
                    loop: false,
                    center: true,
                    startIndex: items_count,
                    controls: false,
                });
            }
        },
        initSlider: function () {
            $('.slider-events-images').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 300,
                infinite: false,
                dots: false,
                prevArrow: '.arrow-element.-next',
                nextArrow: '.arrow-element.-prev'
            });

            $('.js-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 300,
                arrows: false,
                infinite: false,
                dots: true
            })

            $('.gallery-slider-js').each(function () {
                $(this).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 300,
                    arrows: true,
                    infinite: false,
                    dots: true,
                    prevArrow: $(this).parent().find('.arrow-element.-next'),
                    nextArrow: $(this).parent().find('.arrow-element.-prev')
                })
            })


            $('.j-slider-pluses-four').slick({
                mobileFirst: true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: "unslick"
                    }
                ]
            });
            $('.j-slider-pluses--five').slick({
                mobileFirst: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: "unslick"
                    }
                ]
            });
            $('.j-slider-pluses').slick({
                mobileFirst: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: "unslick"
                    }
                ]
            });

            $('.j-slider-apps').slick({
                mobileFirst: true,
                arrows: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: "unslick"
                    }
                ]
            });
            $('.j-subs-slider').slick({
                mobileFirst: true,
                dots: true,
                centerMode: true,
                centerPadding: '35px',
                responsive: [
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            centerMode: false,
                        }
                    },
                ]
            });
            $('.j-slider-cards').slick({
                mobileFirst: true,
                arrows: false,
                dots: true,
                centerMode: true,
                centerPadding: '35px',
                responsive: [
                    {
                        breakpoint: 575,
                        settings: "unslick"
                    }
                ]
            });

            $('.j-slider-mainBanner').slick({
                dots: true,
                infinite: true,
                appendArrows: $('.j-arrows-mainBanner'),
            });

            $('.j-slider').slick({
                dots: true,
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 2
            });
        },
        initSliderNews: function () {
            $('.j-slider-news').slick({
                mobileFirst: true,
                arrows: false,
                dots: false,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 1289,
                        settings: "unslick"
                    }
                ]
            });
        },
        initSliderWidth: function () {
            //позиционирование блока со слайдером событий, который слева располагается, как контейнер, а справа - во всю ширину браузера
            recalcWidth();
            $(window).resize(function () {
                recalcWidth();
            });

            function recalcWidth() {
                var demoContainer = $('.header .container');
                var posLeft = demoContainer.offset().left;
                $('.j-slider-right').css('margin-left', posLeft);
            }
        },
        restaurantTablesSlider: function () {
            let slider = $('.j-restaurant-tables-slider');
            slider.length && slider.not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                dots: false,
                variableWidth: true,
                appendArrows: slider,
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                ]
            });
            let restaurantes = $('.j-restaurantes');
            restaurantes.length && restaurantes.not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                dots: false,
                variableWidth: true,
                appendArrows: $('.j-restaurant-tables-arrows'),
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                ]
            });
        },

        initSelect: function () {
            var $select = $('select.select-control');
            $($select).each(function () {
                let parent_class = $(this).data('parent');

                $(this).select2({
                    minimumResultsForSearch: Infinity,
                    dropdownParent: $('.' + parent_class),
                });
            })

            $select.select2().on('change.select2', function (e) {
                let getVal = e.target.value;
                if (getVal)
                    $(this).addClass('-selected');
                else
                    $(this).removeClass('-selected');
            });
        },
        initSelectDelivery: function () {
            let restaurants = $('.restaurants-wrap');
            let restaurants_items = $(restaurants).find('.restaurants-item');
            let slider_items =
                $(document).on("click", ".restaurantes-slider .item", function () {
                    let item = $(this);
                    let data_place = $(item).data('place');
                    $(restaurants_items).hide().removeClass('-show');
                    $(restaurants).find(`[data-place='${data_place}']`).show().addClass('-show');

                    $('.restaurantes-slider .item').removeClass('-active');
                    item.addClass('-active');
                });
        },
        initOpenBlock: function () {
            $('.open-block_button').click(function () {
                var block = $(this).closest('.open-block');
                var hidden = block.find('.open-block_hidden');
                //opening
                if (!block.hasClass('-open')) {
                    block.addClass('-open');
                    hidden.fadeIn('fast');
                }
                //closing
                else {
                    block.removeClass('-open');
                    hidden.fadeOut('fast');
                }
            });
            $(document).click(function (e) {
                if ($(e.target).closest(".open-block").length) return;
                $('.open-block').removeClass('-open');
                $('.open-block_hidden').fadeOut();
                e.stopPropagation();
            });
        },
        initTabs: function () {
            $(".j-tabs").each(function () {
                var active = 0;
                var activeTab = $(this).find('.tabs-header .-active');
                if (activeTab.length) {
                    active = activeTab.closest('li').index();
                    console.log(active)
                }
                $(this).tabs({
                    active: active
                });
            });

        },

        initRootBanner: function () {
            $('.main-banner .slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: '.main-banner .arrow.-prev',
                nextArrow: '.main-banner .arrow.-next'
            });
        },
        initSpoiler: function () {
            $('.content_spoiler > *:first-child').on('click', function () {
                $(this).parent().toggleClass('-opened');
                $(this).next().slideToggle();
            });
        },
        //плавная прокрутка-якорь
        initAnchor: function () {
            $('a[href^="#"].j-anchor').click(function () {
                var _href = $(this).attr("href");
                functions.scrollTo($(_href));
                return false;
            });
        },
        scrollTo: function (obj) {
            if (obj && obj.length)
                $("html, body").animate({scrollTop: obj.offset().top + "px"});
        },

        switchMapToList: function () {
            $('.j-contact-switch_button').change(function () {
                var wrapper = $('.j-contact-switch_wrapper');
                var blocks = wrapper.find('.j-contact-switch_block');
                blocks.toggleClass('-hidden');
            });
        },
        redirectTo: function (link) {
            window.location.href = "/restaurants/" + link + '';
        },
        initMap: function () {
            var map;
            if ($('#j-map').length) {
                ymaps.ready(function () {
                    map = createMap();
                    createPlaceMarks(points_list);
                    mapUsability();
                });
            }

            function createMap() {
                //если не задано на странице, то значения по умолчанию
                if (!mapCentre)
                    mapCentre = [30.314877, 59.939920];
                if (!mapZoom)
                    mapZoom = 17;

                return new ymaps.Map("j-map", {
                    center: mapCentre,
                    zoom: mapZoom,
                    controls: ['zoomControl', 'typeSelector']
                });

            }

            function createPlaceMarks(points_list) {
                if (points_list) {
                    //var textName = ($(window).width() > 1024) ? 'hintContent' : 'balloonContent';
                    var textName = 'balloonContent';
                    var myCollection = new ymaps.GeoObjectCollection();
                    for (let i = 0; i < points_list.length; i++) {
                        let issuePoint = points_list[i];
                        let point = new ymaps.Placemark(
                            issuePoint.coords,
                            {
                                 [textName]: "<div class='map-text'><div class=\"address\">" + issuePoint.type + " <span class=\"text-nowrap\">" + issuePoint.address + "</span></div><div>"+issuePoint.city+"</div><a onclick='__COMMON.functions().redirectTo(\"" + issuePoint.code + "\")' href=\"#\">Подробнее</a></div>",
                            },
                            {
                                iconLayout: 'default#image',
                                iconImageHref: '/assets/client/images/map.svg',
                                iconImageSize: [43, 52],
                                iconImageOffset: [-20, -50],
                            }
                        );
                        myCollection.add(point);
                    }

                    map.geoObjects.add(myCollection);
                }
            }

            //запрет прокручивания колесом\пальцем для возможности прокрутки страницы
            function mapUsability() {
                map.behaviors.disable('scrollZoom');
                //на мобильных устройствах... (проверяем по userAgent браузера)
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    //... отключаем перетаскивание карты
                    map.behaviors.disable('drag');
                }
            }
        },

        newsAjax: function () {
            $('.j-news').each(function () {
                getNews($(this));

            });

            function getNews(block) {
                var place = block;
                var type = block.attr('data-type');
                var cnt = +block.attr('data-cnt');
                var page = +block.attr('data-page');
                var isSlider = (block.attr('data-slider')) ? 1 : 0;

                fw_MOJAX.send({
                    to: place,
                    url: '/ajax/news/getNews/',
                    data: {group_code: type, page: page, count_on_page: cnt, is_slider: isSlider},
                    overlay_to: place,
                    overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                    user_success: function () {

                    }
                });
            }

            $(document).on('click', '.j-event-show-more', function () {
                var block = $(this).closest('.events-list').find('.row');
                var pageNmb = +block.attr('data-page');
                if (pageNmb > 0) {
                    getNews(block);
                }

                return false;
            });


        },
        eventsFilter: function () {
            if ($('.j-events-slider .item').length) {
                let activeIndex = +$('.j-events-slider .item .element.-active').closest('.item').index();
                //if(activeIndex < 0)
                if (activeIndex < 3)
                    activeIndex = 0;

                tns({
                    container: '.j-events-slider',
                    nav: false,
                    mouseDrag: true,
                    slideBy: 1,
                    swipeAngle: false,
                    autoplay: false,
                    autoWidth: true,
                    loop: false,
                    controls: false,
                    gutter: 15,
                    startIndex: activeIndex,

                    responsive: {
                        768: {
                            gutter: 40,
                        }
                    },
                });
            }
        },
        initWhatsAppLink: function () {
            let whats_template = document.createElement('a');
            whats_template.setAttribute("href", "https://api.whatsapp.com/send/?phone=74950250065&text&app_absent=0");
            whats_template.setAttribute('target', "_blank");
            whats_template.classList.add('whats-app-chat-link');

            document.body.appendChild(whats_template);
            //console.log(document.getElementById('menu-bottom'));
            document.getElementById('menu-bottom').appendChild(whats_template.cloneNode());

        },

        initAccord: function () {
            var close = function (block) {
                block.removeClass('opened');
                block.find('.accord-hidden').css('height', 0);
            }
            $('.accord .j-openAll').click(function () {
                var wrapper = $(this).closest('.accord');
                var hidden = wrapper.find('.accord-hidden');
                var group = $(this).closest('.accord-group');

                var opening = false;
                if (!wrapper.hasClass('opened'))
                    opening = true;

                //closing all
                if (group.length) {
                    var wrapperAll = group.find('.accord');
                    wrapperAll.each(function () {
                        close($(this));
                    });
                } else
                    close(wrapper);

                //opening
                if (opening) {
                    var height = hidden.find('.inner').innerHeight();
                    hidden.css('height', height);
                    wrapper.addClass('opened');
                }


            });
        },

        initAccord1: function () {
            $('.accord .j-openAll').click(function () {
                var wrapper = $(this).closest('.accord');
                var hidden = wrapper.find('.accord-hidden');
                //opening
                if (!wrapper.hasClass('opened')) {
                    var height = hidden.find('.inner').innerHeight();
                    hidden.css('height', height);
                    wrapper.addClass('opened');
                }
                //closing
                else {
                    wrapper.removeClass('opened');
                    hidden.css('height', 0);
                }
            });
        },

        restautantsFunctions: function () {
            var $wrapper = $('.j-slider-restaurants');
            var $items = $wrapper.find('.item');
            var $texts = $wrapper.find('.item .texts');

            let $next_arrow = $($wrapper).parent().find('.arrow-element.-next');
            let $prev_arrow = $($wrapper).parent().find('.arrow-element.-prev');

            let currentSlide;
            let slidesCount;
            let sliderCounter = $('.slider-counter');

            $wrapper.on('init', function (event, slick) {
                updateSliderCounter(slick);
            });

            var updateSliderCounter = function (slick, currentIndex) {
                currentSlide = slick.slickCurrentSlide() + 1;
                slidesCount = slick.slideCount;
                $(sliderCounter).text(currentSlide + '/' + slidesCount)
            };

            $wrapper.on('afterChange', function (event, slick, currentSlide) {
                updateSliderCounter(slick, currentSlide);
            });

            $wrapper.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '0px',
                speed: 700,
                arrows: true,
                infinite: true,
                nextArrow: $($next_arrow),
                prevArrow: $($prev_arrow),
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,

                        }
                    }
                ]
            });


            $(document).on("click", ".restaurants-list .restaurant.item", function (e) {
                if (e.currentTarget.classList.contains('slick-current')) {
                    console.log(e.currentTarget);
                } else {
                    e.preventDefault();
                    let current_slide = $($wrapper).find('.restaurant.-active-slide');
                    let current_index = $(current_slide).data('slick-index');
                    let new_index = $(e.currentTarget).data('slick-index');
                    $wrapper.slick('slickGoTo', new_index);
                }
            })


            //ширина элементов на странице списка ресторанов
            function calcRestautantWidth() {
                if ($wrapper.hasClass('slick-slider'))
                    return false;
                var demoContainer = $('.header .container');
                var containerWidth = demoContainer.width();

                var restaurantCnt = $items.length;
                var restaurantWidth = containerWidth / restaurantCnt;

                $items.css('width', restaurantWidth);
                $texts.css('width', restaurantWidth);
            }

            //вычисление отступа сверху блока текстов
            function restaurantTopPadding() {
                var headerH = $('.header').outerHeight();
                var restaurantsH = $items.innerHeight();
                var innerHeightMax = 0;

                $items.each(function () {
                    var $inner = $(this).find('.texts');
                    var height = $inner.outerHeight();
                    if (height > innerHeightMax)
                        innerHeightMax = height;
                });

                //calcing halp padding
                var p = (restaurantsH - innerHeightMax) / 2;
                if (p > headerH) {
                    var res = p - headerH;
                    $items.find('.texts').css('margin-top', res);
                }
                $texts.css('height', innerHeightMax);
            }
        },

        functionBodyScrollFix: function () {
            var scrollbarWidth = (window.innerWidth - $(document).width());
            if (scrollbarWidth) {
                $('body').css('padding-right', scrollbarWidth);
                $('.fixed-reserved').css('margin-right', scrollbarWidth);
            }
        },
        functionBodyScrollFix_toggle: function () {
            $('body').toggleClass('-scroll-fixed');
        },
        initToggleDelivery: function () {
            let toggle_heading = $('.toggle-heading');
            let list_content = $('.toggle-heading_item');
            let toggle_items = $(toggle_heading).find('.toggle-item');
            $(toggle_items).on('click', function () {
                var select_type = $(this).data('type');
                $(toggle_items).removeClass('-active');
                $(list_content).hide();

                $(`[data-type='${select_type}']`).show().addClass('-active');

                $('.select-restaurant').removeClass().addClass(`select-restaurant ${select_type}`);
                $('.restaurants-wrap').removeClass().addClass(`restaurants-wrap type-${select_type}`);
                let restaurant = $('.restaurants-wrap').find('.restaurants-item.-show');

                if ($(restaurant).hasClass(`${select_type}`)) {
                    /*console.log('Выбранный ресторан имеет такой-же тип доставки');*/
                } else {
                    $(restaurant).hide();
                    $('.select-delivery').select2().val(0).trigger('change.select2');
                }
            })
        },
        initPromoTimer: function () {
            const deadline = new Date(deadline_year, deadline_month - 1, deadline_day, deadline_hour);
            let timerId = null;

            function declensionNum(num, words) {
                return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]];
            }

            function countdownTimer() {
                const diff = deadline - new Date();
                if (diff <= 0) {
                    clearInterval(timerId);
                    console.log('finish');

                    var timerText = document.querySelector('.timer');
                    timerText.classList.add('-finished')
                    timerText.innerHTML = 'Мероприятие завершено!'
                }
                const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
                const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
                const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
                const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
                $days.textContent = days < 10 ? '0' + days : days;
                $hours.textContent = hours < 10 ? '0' + hours : hours;
                $minutes.textContent = minutes < 10 ? '0' + minutes : minutes;
                $seconds.textContent = seconds < 10 ? '0' + seconds : seconds;
                $days.dataset.title = declensionNum(days, ['день', 'дня', 'дней']);
                $hours.dataset.title = declensionNum(hours, ['час', 'часа', 'часов']);
                $minutes.dataset.title = declensionNum(minutes, ['минута', 'минуты', 'минут']);
                $seconds.dataset.title = declensionNum(seconds, ['секунда', 'секунды', 'секунд']);
            }

            const $days = document.querySelector('.timer__days');
            const $hours = document.querySelector('.timer__hours');
            const $minutes = document.querySelector('.timer__minutes');
            const $seconds = document.querySelector('.timer__seconds');
            countdownTimer();
            timerId = setInterval(countdownTimer, 1000);

        },
        initEventFormElements() {
            this.selectEventForm();
            this.eventInputNumber();
            this.showBeautyDate();
            this.initEventName();
        },
        selectEventForm() {
            const $select = $('select.form-control.event');
            if ($select.length) {
                _FORMS.event_rest_id = $select.val();
                $select.select2({minimumResultsForSearch: Infinity});

                $select.each(function () {
                    const $this = $(this);
                    const optionsCount = $this.find('option').length;

                    if (optionsCount === 1) {
                        $this.prop('disabled', true);
                        $this.next('.select2-container').addClass('select2-container--disabled');
                    }
                });

                $select.select2().on('change.select2', function (e) {
                    _FORMS.event_rest_id = e.target.value;
                    _FORMS.updateFormInfo(_FORMS.event_rest_id, window.eventId);
                });

                $('input[name="email"]').each(function() {
                    $(this).css({
                        'position': 'relative',
                        'z-index': '10'
                    });
                })

                initPhoneInput();
                _FORMS.initAgreeWin();
                _FORMS.initOfferWin();
            }
        },
        eventInputNumber(oldPlacesCount, newPlacesCount) {
            let $input = $('input.form-control.event[type=number]'),
                placesCount = parseInt($('#places_count').val()),
                storedFormData = JSON.parse(localStorage.getItem('formData')),
                ticketPrice = parseFloat($('#ticket-price').text());
            if ($input.length) {
                $input.attr('readonly', true);
                checkPlacesCount($input);
                $input.spinner({
                    min: 1,
                    step: 1,
                    max: placesCount,
                    spin: function(event, ui) {
                        let newValue = ui.value,
                            totalPrice = newValue * ticketPrice;

                        $('#total').text(totalPrice + ' руб.');

                        __COMMON.functions().checkGuestCount(newValue, placesCount);
                        __COMMON.functions().updateButtonState(newValue, placesCount);

                    },
                });
                let cookie = getCookie('eventData');
                if (storedFormData && cookie && cookie.length) {
                    let clientsValue = __COMMON.functions().getClientValue(storedFormData);
                    if (clientsValue !== null && !isNaN(clientsValue)) {
                        let initialTotalPrice = clientsValue * ticketPrice;
                        $input.val(clientsValue);
                        $('#total').text(initialTotalPrice + ' руб.');
                    }
                }

                if (oldPlacesCount > newPlacesCount) {
                    $input.val(newPlacesCount);
                    checkPlacesCount($input);

                    let totalPrice = newPlacesCount * ticketPrice;
                    $('#total').text(totalPrice + ' руб.');

                    __COMMON.functions().updateButtonState(oldPlacesCount, newPlacesCount);
                    __COMMON.functions().checkGuestCount(oldPlacesCount, newPlacesCount)
                }
                function checkPlacesCount($input) {
                    $input.each(function() {
                        if (parseInt($(this).val()) === 0) {
                            $(this).val(1);
                        }
                    });
                }
            }
        },
        checkGuestCount: function(value, placesCount) {
            if (value >= placesCount) {
                $('.error_wrapper.int').css({'visibility': 'visible', 'opacity': '1'});
                $('#error_places').text('Выбрано максимальное количество гостей');
            } else {
                $('.error_wrapper.int').css({'visibility': 'hidden', 'opacity': '0'});
                $('#error_places').html('&nbsp;');
            }
        },
        getClientValue(formData) {
           for (let i = 0; i < formData.length; i++) {
              if (formData[i].name === 'clients') {
                return parseInt(formData[i].value);
              }
           }
            return null;
        },
        updateButtonState: function(value, placesCount) {
            if (value >= placesCount) {
                $('.ui-spinner-up').addClass('ui-state-disabled')
            } else {
                $('.ui-spinner-up').removeClass('ui-state-disabled')
            }

            if (value === 1) {
                $('.ui-spinner-down').addClass('ui-state-disabled')
            } else {
                $('.ui-spinner-down').removeClass('ui-state-disabled')
            }
        },
        showBeautyDate: function() {
            const input = document.querySelector('input[name="date"]');
            if (input) {
                const dateStr = input.value;
                const date = new Date(dateStr);
                const options = { day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit' };
                input.removeAttribute('disabled');
                input.setAttribute('readonly', 1);
                input.value = date.toLocaleDateString('ru-RU', options).replace(',', '');
            }
        },
        initEventName: function () {
            const input = $("input[name='event_name']");
            if (input.length) {
                input.val(window.eventName);
            }
        }
    };
    return _init;
}();
