/**
 * Создаем фреймворк для более удобной и автоматизированной работы с Ajax.
 */

var fw_MOJAX = function(){
	if(typeof(fw_MOJAX) !== "undefined") return fw_MOJAX;
	"use strict";
	var SINGLE_DEBUG_TITLE = "Попытка запустить второй раз защищенный Ajax запрос";
	var SINGLE_DEBUG_TEXT = "Не стоит этого делать...";
	var SINGLE_DEBUG_MODE = "info";

	var NONE = -1;
	var FULL_OVERLAY = 1;
	var BLOCK_OVERLAY = 2;
	var LOADER_OVERLAY = 3;
	// Типы для лоадера
	var OVERLAY_MAIN = "/images/admin/Mojax/block_loader_content_img.gif";
	var OVERLAY_LOADER = "/images/admin/Mojax/line_loader_content_img.gif";
	var OVERLAY_MINI = "/images/admin/Mojax/mini_loader_content_img.gif";

	// Скины для лоадера
	var SKIN_COOL = 1;
	var SKIN_OLD = 2;

	// Для дефолтного контента.
	var EMPTY = "";
	var NOT_SET = false;

	var INSERT_MODE_APPEND = 0;
	var INSERT_MODE_PREPEND = 1;
	var INSERT_MODE_HTML = 2;
	var INSERT_MODE_REPLACE = 3;
	var $mojax = {};
	$mojax = {
		NONE:NONE,
		FULL_OVERLAY:FULL_OVERLAY,
		BLOCK_OVERLAY:BLOCK_OVERLAY,
		LOADER_OVERLAY:LOADER_OVERLAY,
		// Типы оверлеев
		OVERLAY_MAIN:OVERLAY_MAIN,
		OVERLAY_LOADER:OVERLAY_LOADER,
		OVERLAY_MINI:OVERLAY_MINI,
		// Типы скинов
		SKIN_COOL:SKIN_COOL,
		SKIN_OLD:SKIN_OLD,

		// Типы вставок
		INSERT_MODE_HTML:INSERT_MODE_HTML,
		INSERT_MODE_APPEND:INSERT_MODE_APPEND,
		INSERT_MODE_PREPEND:INSERT_MODE_PREPEND,
		INSERT_MODE_REPLACE:INSERT_MODE_REPLACE,
		// Типы контента
		NOT_SET:NOT_SET,
		EMPTY:EMPTY,
		// Хранилище
		named_instances:{},
		overlay_instances:{
			FULL_OVERLAY:{
				count:0
			},
			BLOCK_OVERLAY:{},
			LOADER_OVERLAY:{}
		},
		disabled_buttons:{},
		// Опции для конкретного хранилища
		options:{
			// Основное расширение
			ext: "main",
			// Параметры настройки Ajax
			type: "POST",
			async: true,
			// Реакция на разные статусы
			statusCode:{},
			// Функции эвентов пользователя.
			user_success: null,
			user_fail: null,
			user_error: null,
			user_complete: null,
			// Входные доп данные
			to:"",
			insert_mode:INSERT_MODE_HTML,
			url:"",
			data:{},
			user_data:{},
			// Параметр формы для субмита
			form:null,
			// Набор валидаторов на входные данные.
			validations:{},
			// Опции для индексирования
			code:false,
			single_mode:false, // Если FALSE - старый запрос сбрасывается, иначе ожидается завершение. Не забудьте проставить CODE иначе не работает
			// Опции для оверлеев
			overlay_mode:FULL_OVERLAY,
			overlay_skin:SKIN_COOL,
			overlay_to:"",
			overlay_type:OVERLAY_MAIN,
			overlay_z_index:100000,
			disable_path:[],
			disable_path_with_name:{},
			// Дополнительные ключи для действий
			nl2br:false,
			default_content:NOT_SET
		},
		// Основной объект
		send:function(options){
			options = options || {};
			var user_options = {};
			// Проверяем на наличие формы при подаче запроса.
			if(!$.empty(options.form)){
				var $form = $(options.form);
				// Заполняем нужные параметры из формы.
				if($.empty(options.url)){
					options.url = $form.attr('action');
				}
				var form_post_data = fw_MAMMON.serializePostWithCKEditor($form);
				options.data = form_post_data.concat(fw_MAMMON.getPostArray(options.data));
				//Установка оверлея на форму.
				if($.empty(options.overlay_mode)){
					options.overlay_mode = BLOCK_OVERLAY;
				}
				if($.empty(options.overlay_skin)){
					options.overlay_skin = SKIN_COOL;
				}
				if($.empty(options.overlay_to)){
					options.overlay_to = $form;
				}
			}
			//Получаем полностью сгненерированный массив опций - мёржим его с дефолтным и пускаем на подачу запроса.
			$.extend(user_options,$mojax.options,options);
			// Обработка кода
			if(!$.empty(user_options.code)){
				if($.empty($mojax.named_instances[user_options.code])){
					$mojax.named_instances[user_options.code] = $mojax.getInstance(user_options);
				}
				else{
					if(!$.empty(user_options.single_mode)){
						// Ничего не делаем. Можем вывести предупреждение чт оне стоит выполнять второй запрос.
						fw_ADMIN.addToDebugPanel({value:SINGLE_DEBUG_TEXT,from:user_options.url,message:SINGLE_DEBUG_TITLE,type:SINGLE_DEBUG_MODE});
					}
					else{
						// Отменяем первый и варим второй.
						$mojax.named_instances[user_options.code].jqXHR.abort();
						$mojax.named_instances[user_options.code] = $mojax.getInstance(user_options);
						return $mojax.named_instances[user_options.code];
					}
				}
			}
			else{
				return $mojax.getInstance(user_options);
			}
		},
		/**
		 * data -
		 *     overlay_to
		 *     ext (по дефолту берётся main)
		 * @param data
		 */
		createBlockOverlay:function(data){
			// Мержим конфиги
			var default_data = {
				overlay_to:"",
				ext:'main'
			};
			var merged_data = {};
			$.extend(merged_data,default_data,data);

			var jQuery_objects = $(merged_data.overlay_to);
			var overlay_containers_id = jQuery_objects.getUniqIdArr();
			for(var ui_key = 0; ui_key < overlay_containers_id.length; ui_key++){
				if($.empty($mojax.overlay_instances.BLOCK_OVERLAY[overlay_containers_id[ui_key]])){
					$mojax.overlay_instances.BLOCK_OVERLAY[overlay_containers_id[ui_key]] = {count:0};
				}
				$mojax.overlay_instances.BLOCK_OVERLAY[overlay_containers_id[ui_key]].count++;
				$mojax.ext_functions[merged_data.ext].startBlockOverlay(overlay_containers_id[ui_key], merged_data.overlay_skin);
			}
		},
		removeBlockOverlay:function(data){
			// Мержим конфиги
			var default_data = {
				overlay_to:"",
				ext:'main'
			};
			var merged_data = {};
			$.extend(merged_data,default_data,data);

			var jQuery_objects = $(merged_data.overlay_to);
			var overlay_containers_id = jQuery_objects.getUniqIdArr();
			for(var ui_key = 0; ui_key < overlay_containers_id.length; ui_key++){
				$mojax.overlay_instances.BLOCK_OVERLAY[overlay_containers_id[ui_key]].count--;
				if($mojax.overlay_instances.BLOCK_OVERLAY[overlay_containers_id[ui_key]].count === 0){
					$mojax.ext_functions[merged_data.ext].hideBlockOverlay(overlay_containers_id[ui_key]);
				}
			}
		},
		createFullOverlay:function(data){
			// Мержим конфиги
			var default_data = {
				overlay_to:"",
				ext:'main'
			};
			var merged_data = {};
			$.extend(merged_data,default_data,data);
			$mojax.overlay_instances.FULL_OVERLAY.count++;
			$mojax.ext_functions[merged_data.ext].startFullOverlay(merged_data.overlay_z_index,merged_data.overlay_skin);
		},
		removeFullOverlay:function(data){
			// Мержим конфиги
			var default_data = {
				overlay_to:"",
				ext:'main'
			};
			var merged_data = {};
			$.extend(merged_data,default_data,data);
			if(--$mojax.overlay_instances.FULL_OVERLAY.count === 0){
				$mojax.ext_functions[merged_data.ext].hideFullOverlay();
			}
		},
		extend:function(to_code,redefined_functions_object){
			// Работаем с оригинальным объектом.
			if(fw_MOJAX.ext_functions[to_code] !== undefined){
				alert("Попытка переопределить существующий код в MOJAX -> "+to_code);
			}
			else{
				fw_MOJAX.ext_functions[to_code] = $.extend({},$mojax.ext_functions['main'],redefined_functions_object);
			}
		},
		getInstance:function(user_options){
			var instance = $mojax.createInstance(user_options);
			instance.init();
			return instance;
		},
		createInstance:function(i_options){

			// Приватные константы
			var PHP_DEBUG_TITLE = "Debug PHP:";
			var PHP_DEBUG_TYPE = "info";
			var JS_DEBUG_TITLE = "Debug Javascript:";
			var JS_FUNC_DEBUG_TITLE = "Debug Function Javascript:";
			var JS_DEBUG_TYPE = "system_debug";
			var PHP_DEBUG_DATA = 1;
			var PHP_RESPONSE_DATA = 2;
			var ABSENT_URL = {
				value:"Отсутствует поле URL",
				from:"/",
				message:"Отсутствует поле URL",
				type:"warning"
			};
			var ABSENT_EXT = "Отсутствует расширение для кода "+i_options.ext;

			function splitResponse(response,inst){
				var is_valid_json;
				try {
					JSON.parse(response);
					is_valid_json = true;
				} catch (e) {
					is_valid_json = false;
				}
				var incoming_parts = {
					debug: is_valid_json ? '' : response,
					json: is_valid_json ? response : ''
				};
				// Обрабатываем результат
				if(!$.empty(incoming_parts.debug)){
					$mojax.ext_functions[inst.instance_options.ext].addToDebugPanel({value:incoming_parts.debug,from:inst.url,message:"[1]"+PHP_DEBUG_TITLE,type:PHP_DEBUG_TYPE});
				}
				return incoming_parts.json;
			}
			function checkExt(inst){
				if($.empty($mojax.ext_functions[inst.ext])){
					inst.selfInstanceDelete();
					alert(ABSENT_EXT);
					return false;
				}
				return true;
			}
			function checkUrl(inst){
				if($.empty(inst.url)){
					inst.selfInstanceDelete();
					$mojax.ext_functions[inst.instance_options.ext].addToDebugPanel(ABSENT_URL);
					return false;
				}
				return true;
			}
			/**
			 validation : {
				id : {
					type : 'i',
					message : 'errmessage'
				}
			}
			 */
			function checkValidation(inst){
				var errors;
				if($.empty(inst.validations) || !(errors = fw_MAMMON.validateAll(inst.data, inst.validations))) return true;
				for(var i in errors){
					if(errors.hasOwnProperty(i)){
						$mojax.ext_functions[inst.ext].validationCallback(errors[i].item, errors[i].value);
					}
				}
				return false;
			}
			function disableButton(inst,disable_selector,switch_text){
				if($.empty($mojax.disabled_buttons[disable_selector])){
					$mojax.disabled_buttons[disable_selector] = {count:0};
				}
				$mojax.disabled_buttons[disable_selector].count++;
				$(disable_selector).addClass("mojax_disabled").attr("disabled","disabled");
				if(switch_text){
					inst.disabled_path_with_name[disable_selector] = $(disable_selector).text();
					$(disable_selector).text(inst.instance_options.disable_path_with_name[disable_selector]);
				}
			}
			function processAddOverlay(inst){
				switch(inst.instance_options.overlay_mode){
					case FULL_OVERLAY:{
						$mojax.createFullOverlay(inst.instance_options);
						break;
					}
					case BLOCK_OVERLAY:{
						if(!$.empty(inst.instance_options.overlay_to)){
							$mojax.createBlockOverlay(inst.instance_options);
						}
						break;
					}
					case LOADER_OVERLAY:{
						if(!$.empty(inst.instance_options.overlay_to)){
							var jQuery_objects = $(inst.instance_options.overlay_to);
							inst.overlay_containers_id = jQuery_objects.getUniqIdArr();
							for(var ui_key_loader = 0; ui_key_loader < inst.overlay_containers_id.length; ui_key_loader++){
								var overlay_id = inst.overlay_containers_id[ui_key_loader];
								if($.empty($mojax.overlay_instances.LOADER_OVERLAY[overlay_id])){
									$mojax.overlay_instances.LOADER_OVERLAY[overlay_id] = {count:0};
								}
								$mojax.overlay_instances.LOADER_OVERLAY[overlay_id].count++;
								$mojax.ext_functions[inst.instance_options.ext].startLoaderOverlay(overlay_id,inst.instance_options.overlay_type,inst.instance_options.overlay_skin);
							}
						}
						break;
					}
				}
			}
			function processRemoveOverlay(inst){
				switch(inst.instance_options.overlay_mode){
					case FULL_OVERLAY:{
						$mojax.removeFullOverlay(inst.instance_options);
						break;
					}
					case BLOCK_OVERLAY:{
						if(!$.empty(inst.instance_options.overlay_to)){
							$mojax.removeBlockOverlay(inst.instance_options);
						}
						break;
					}
					case LOADER_OVERLAY:{
						if(!$.empty(inst.instance_options.overlay_to)){
							for(var ui_key_loader = 0; ui_key_loader < inst.overlay_containers_id.length; ui_key_loader++){
								var overlay_id = inst.overlay_containers_id[ui_key_loader];
								$mojax.overlay_instances.LOADER_OVERLAY[overlay_id].count--;
								if($mojax.overlay_instances.LOADER_OVERLAY[overlay_id].count === 0){
									$mojax.ext_functions[inst.instance_options.ext].hideLoaderOverlay(overlay_id);
								}
							}
						}
						break;
					}
				}
			}
			// Описание основного объекта
			return function(options){
				return {
					instance_options:options,
					ext:options.ext,
					user_data:options.user_data,
					url:options.url+((!$.empty(options.to) && !$.empty(options.url))?"?ajax_display_mode":""),
					to: options.to,
					data:options.data,
					data_obj:fw_MAMMON.getObjFromPost(options.data),
					validations:i_options.validations,
					overlay_data:null,
					disabled_path_with_name:{},
					overlay_containers_id:[],
					jqXHR:null,
					json_response:{
						success:false,
						content:"",
						json:{},
						messages_obj:{},
						system_debug_obj:{}
					},

					// Системные функции
					init:function(){
						// Проверяем наличие расширения под которым запускаем запрос.
						if(!checkExt(this)) return false;
						// Проверяем наличие урла
						if(!checkUrl(this)) return false;
						// Кастомная валидация
						if(!checkValidation(this)) return false;
						this.user_data = $.extend({}, this.user_data, {core_to: this.to, core_data: this.data});
						const options = {
							type: this.instance_options.type,
							async: this.instance_options.async,
							statusCode:this.instance_options.statusCode,
							url: this.url,
							data: (this.data),
							context: this,
							beforeSend: this.beforeSend,
							success: this.success,
							error: this.error,
							complete:  this.complete,
						};
						if(this.data instanceof FormData){
							options.processData = false;
							options.contentType = false;
						}
						this.jqXHR = $.ajax(options);
						return true;
					},
					beforeSend:function(){
						// Чистим контент
						if(!$.empty(this.to) && this.instance_options.default_content !== NOT_SET){
							var target = $(this.to);
							target.html(this.instance_options.default_content);
						}
						processAddOverlay(this);
						// Дизэйблим кнопки.
						for(var i = 0; i < this.instance_options.disable_path.length ; i++){
							disableButton(this,this.instance_options.disable_path[i]);
						}
						// Дизэйблим кнопки и меняем у них текст.
						for(var selector in this.instance_options.disable_path_with_name){
							if(this.instance_options.disable_path_with_name.hasOwnProperty(selector)){
								disableButton(this,selector,true);
							}
						}
					},
					selfInstanceDelete:function(){
						if(!$.empty(this.instance_options.code) && !$.empty($mojax.named_instances[this.instance_options.code])){
							delete $mojax.named_instances[this.instance_options.code];
						}
					},

					// Функции на эвентах
					success:function(response,textStatus){
						processRemoveOverlay(this);
						try {
							if(!(response = this.smartProcessData(response))){
								//невалидный response, вся строка ушла в дебаг
								return false;
							}
							// Валидация JSON перехватывается верхним блоком try/catch
							this.json_response = $.parseJSON(response);
							try{
								this.processAjaxResponse();
								//Проверка выполнения
								if(this.json_response.success && this.to){
									//Поулчаем объект получаетеля
									var target = $(this.to);
									//Вывод контента
									switch(this.instance_options.insert_mode){
										case INSERT_MODE_APPEND:
											target.append(this.json_response.content);
											break;
										case INSERT_MODE_PREPEND:
											target.prepend(this.json_response.content);
											break;
										case INSERT_MODE_HTML:
											target.html(this.json_response.content);
											break;
										case INSERT_MODE_REPLACE:
											target.replaceWith(this.json_response.content);
											break;
									}
								}
							}
							catch(fe){
								console.error(fe);
								$mojax.ext_functions[this.ext].addToDebugPanel({value:"ОШИБКА JS при выводе контента (Смотри консоль...)",from:this.url,message:JS_DEBUG_TITLE,type:JS_DEBUG_TYPE});
							}
							//Функция после выполнения
							var func_name = (this.json_response.success) ? "user_success" : "user_fail";
							if(this.instance_options[func_name]){
								try{
									this.instance_options[func_name](this.json_response.json,this.user_data,textStatus);
								}
								catch(fe){
									console.error(fe);
									$mojax.ext_functions[this.ext].addToDebugPanel({value:"ОШИБКА JS при выполнении функции "+func_name+" (Смотри консоль...)",from:this.url,message:JS_FUNC_DEBUG_TITLE,type:JS_DEBUG_TYPE});
								}
							}
							return true;
						}
						catch(e) {
							$mojax.ext_functions[this.ext].addToMessagesPanel({message:"Ошибка в передаче JSON.\n---------------\n"+response+"\n---------------\n"+e,uri:this.url});
							return false;
						}
					},
					error:function(jqXHR,textStatus,errorThrown){
						processRemoveOverlay(this);
						//Функция после выполнения
						var func = (this.instance_options.user_error)?this.instance_options.user_error:false;
						if(func){
							try{
								func(this,textStatus,errorThrown);
							}
							catch(fe){
								console.error(fe);
								$mojax.ext_functions[this.ext].addToDebugPanel({value:"ОШИБКА JS при выполнении функции user_error (Смотри консоль...)",from:this.url,message:JS_FUNC_DEBUG_TITLE,type:JS_DEBUG_TYPE});
							}
						}
						var response = '';
						if(!(response = this.smartProcessData(jqXHR.responseText))){
							//невалидный response, вся строка ушла в дебаг
							return false;
						}
					},
					complete:function(jqXHR, textStatus){
						//Функция после выполнения
						var func = (this.instance_options.user_complete)?this.instance_options.user_complete:false;
						if(func){
							try{
								func(this,textStatus);
							}
							catch(fe){
								console.error(fe);
								$mojax.ext_functions[this.ext].addToDebugPanel({value:"ОШИБКА JS при выполнении функции user_complete (Смотри консоль...)",from:this.url,message:JS_FUNC_DEBUG_TITLE,type:JS_DEBUG_TYPE});
							}
						}
						// АнДизэйблим кнопки или другие элементы.
						for(var i = 0; i < this.instance_options.disable_path.length ; i++){
							var to_disable_selector = this.instance_options.disable_path[i];
							$mojax.disabled_buttons[to_disable_selector].count--;
							if($mojax.disabled_buttons[to_disable_selector].count === 0){
								$(to_disable_selector).removeClass("mojax_disabled").removeAttr("disabled");
							}
						}
						for(var selector in this.instance_options.disable_path_with_name){
							if(this.instance_options.disable_path_with_name.hasOwnProperty(selector)){
								$mojax.disabled_buttons[selector].count--;
								if($mojax.disabled_buttons[selector].count === 0){
									$(selector).removeClass("mojax_disabled").removeAttr("disabled");
									if(!$.empty(this.disabled_path_with_name[selector])){
										$(selector).text(this.disabled_path_with_name[selector]);
									}
								}
							}
						}
						this.selfInstanceDelete();
					},

					// Доп функционал
					smartProcessData: function(response){
						if(this.instance_options.nl2br){
							response = $.nl2br(response);
						}
						return splitResponse(response,this);
					},
					processAjaxResponse:function(){
						//Вывод сообщений
						if(!$.empty(this.json_response.messages_obj)){
							this.displayAllMessages(this.json_response.messages_obj);
						}
						//Вывод дебага
						if(!$.empty(this.json_response.system_debug_obj)){
							this.displayAllDebug(this.json_response.system_debug_obj);
						}
						//Обработка пермишена
						if(this.json_response.perm_denied){
							$mojax.ext_functions[this.ext].permDeniedProcessing();
						}
					},

					// Функции выдачи данных пользователю.
					displayAllMessages:function(msg){
						for(var key = 0; key < msg.length; key++){
							$mojax.ext_functions[this.ext].addToMessagesPanel(msg[key]);
						}
					},
					displayAllDebug:function(dbg){
						for(var key = 0; key < dbg.length; key++){
							$mojax.ext_functions[this.ext].addToDebugPanel(dbg[key]);
						}
					}
				}
			}(i_options);
		},
		// Функции для работы с выводом дебага и сообщений. Могут быть переопределены для создания экстенда.
		ext_functions:{
			main:function(){
				var OVERLAY_ANIMATION_DURATION = 400;

				var FULL_OVERLAY_BG_CSS = {
					width:"100%",
					height:"100%",
					top:0,
					left:0,
					position:"fixed",
					background:"#fff",
					display:"none"
				};
				var FULL_OVERLAY_CONTENT_CSS = {
					width:300,
					height:300,
					top:"50%",
					left:"50%",
					margin:"-150px 0 0 -150px",
					position:"fixed",
					display:"none",
					'text-align':"center"
				};
				var BLOCK_OVERLAY_BG_CSS = {
					width:"100%",
					height:"100%",
					top:0,
					left:0,
					position:"absolute",
					background: "#fff",
					display:"none",
					"z-index" : 10
				};
				var BLOCK_OVERLAY_CONTENT_CSS = {
					width:32,
					height:32,
					top:"50%",
					left:"50%",
					margin:"-16px 0 0 -16px",
					position:"absolute",
					display:"none",
					'text-align':"center"
				};
				var LOADER_OVERLAY_CONTENT_CSS = {
					width:32,
					height:32,
					margin:"5px",
					display:"none"
				};
				function renderCoolOverlay(loader_mode, parent_obj) {
					var MAX_SIZE = 100;
					var MIN_SIZE = 20;
					var text_modifier = parent_obj.outerWidth() < MAX_SIZE || parent_obj.outerHeight() < MAX_SIZE;
					var __text = text_modifier ? 'M' : 'MAJOR';
					var __min_size  = (parent_obj.outerWidth() > parent_obj.outerHeight()) ? ((parent_obj.outerHeight() > MAX_SIZE) ? MAX_SIZE : ((parent_obj.outerHeight() < MIN_SIZE) ? MIN_SIZE : parent_obj.outerHeight())) : ((parent_obj.outerWidth() > MAX_SIZE) ? MAX_SIZE : ((parent_obj.outerWidth() < MIN_SIZE) ? MIN_SIZE : parent_obj.outerWidth()));
					var _circular;
					var _text;
					var _circle;
					var _css_circular = {width: __min_size, height: __min_size};
					var _css_text = {width: __min_size, height: __min_size, "line-height": __min_size + "px"};
					switch (loader_mode) {
						case FULL_OVERLAY: {
							$.extend(_css_circular,{position: 'fixed','z-index':'100001'});
							$.extend(_css_text,{position: 'fixed','z-index':'100001'});
							break;
						}
					}
					if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1))
					{
						__min_size = (__min_size < 110) ? __min_size - 15 : __min_size;
						_circular = $('<div class="circular ie"></div>').css($.extend(_css_circular, {width: __min_size, height: __min_size}));
						_text = $('<div class="text ie">' + __text + '</div>').css(_css_text);
					}
					else {
						_circle  = $('<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>');
						_circular = $('<svg class="circular" viewBox="25 25 50 50">'+ _circle.prop('outerHTML') +'</svg>').css(
							$.extend({}, _css_circular)
						);
						_text = $('<div class="text">' + __text + '</div>').css(
							$.extend({},_css_text)
						);
					}
					return $('<div class="mojax_block_overlay_content">' + _circular.prop('outerHTML') + _text.prop('outerHTML') + '</div>');
				}
				function loaderEndDisplay(loader){
					loader.stop().fadeTo(OVERLAY_ANIMATION_DURATION,.0,function(){$(this).remove();});
				}
				function loaderRefreshDisplay(loader,full){
					var opacity = .8;
					if(full){opacity = 1;}
					loader.stop().fadeTo(OVERLAY_ANIMATION_DURATION,opacity);
				}

				return {
					permDeniedProcessing: function(){
						fw_MOJAX.createFullOverlay();
						location.reload();
					},
					addToMessagesPanel: fw_ADMIN.addToMessagesPanel,
					addToDebugPanel: fw_ADMIN.addToDebugPanel,
					startFullOverlay:function(z_index,skin){
						var overlay_bg = $("#mojax_full_overlay");
						var overlay_content = $(".mojax_block_overlay_content");
						if(!overlay_bg.emptySet() && !overlay_bg.emptySet()){
							loaderRefreshDisplay(overlay_bg);
							loaderRefreshDisplay(overlay_content,true);
						}
						else{
							switch (skin) {
								case SKIN_COOL:{
									overlay_content = renderCoolOverlay(FULL_OVERLAY,$('body'));
									overlay_bg = $('<div id="mojax_full_overlay"></div>').css(
										$.extend({},FULL_OVERLAY_BG_CSS,{'z-index':z_index})
									);
									break;
								}
								case SKIN_OLD:{
									// Не работает в IE 6
									overlay_bg = $('<div id="mojax_full_overlay"></div>').css(
										$.extend({},FULL_OVERLAY_BG_CSS,{'z-index':z_index})
									);
									overlay_content = $('<div id="mojax_full_overlay_content"><p>Загрузка...</p><img src="/images/admin/Mojax/full_loader_content_img.gif"></div>').css(
										$.extend({},FULL_OVERLAY_CONTENT_CSS,{'z-index':z_index + 1})
									);
									break;
								}
							}
							loaderRefreshDisplay(overlay_bg);
							loaderRefreshDisplay(overlay_content,true);
							var parent_obj = $("body:first");
							parent_obj.append(overlay_bg).append(overlay_content);
						}
					},
					hideFullOverlay:function(){
						var overlay_bg = $("#mojax_full_overlay");
						var overlay_content = overlay_bg.siblings(".mojax_block_overlay_content");
						loaderEndDisplay(overlay_bg);
						loaderEndDisplay(overlay_content);
					},

					startBlockOverlay:function(uniq_id, skin){
						var parent_obj = $.getByUniq(uniq_id);
						var overlay_bg = parent_obj.children(".mojax_block_overlay");
						var overlay_content = parent_obj.children(".mojax_block_overlay_content");

						var _min_size = (parent_obj.outerWidth() < 20 || parent_obj.outerHeight() < 20) ? {"min-width":"30px", "min-height":"30px"} : {};
						if(!overlay_bg.emptySet() && !overlay_bg.emptySet()){
							loaderRefreshDisplay(overlay_bg);
							loaderRefreshDisplay(overlay_content,true);
						}
						else{
							if(parent_obj.css('position') === "static"){
								parent_obj.css({position:"relative"});
							}

							switch(skin){
								case SKIN_COOL: {
									overlay_content = renderCoolOverlay(BLOCK_OVERLAY, parent_obj).css(_min_size);
									overlay_bg = $('<div class="mojax_block_overlay"></div>').css(BLOCK_OVERLAY_BG_CSS);
									break;
								}
								case SKIN_OLD: {
									overlay_bg = $('<div class="mojax_block_overlay"><img src="/images/admin/Mojax/block_loader_overlay.png" style="display:none;"/></div>').css(
										$.extend({},BLOCK_OVERLAY_BG_CSS)
									);
									overlay_content = $('<div class="mojax_block_overlay_content"><img src="/images/admin/Mojax/block_loader_content_img.gif" style="width:32px;height:32px;"></div>').css(
										$.extend({},BLOCK_OVERLAY_CONTENT_CSS)
									);
									break;
								}
							}

							loaderRefreshDisplay(overlay_bg);
							loaderRefreshDisplay(overlay_content,true);
							parent_obj.append(overlay_bg).append(overlay_content);
						}
					},
					hideBlockOverlay:function(uniq_id){
						var parent_obj = $.getByUniq(uniq_id);
						var overlay_bg = parent_obj.children(".mojax_block_overlay");
						var overlay_content = parent_obj.children(".mojax_block_overlay_content");
						loaderEndDisplay(overlay_bg);
						loaderEndDisplay(overlay_content);
					},

					startLoaderOverlay:function(uniq_id,loader_type,skin){
						var parent_obj = $.getByUniq(uniq_id);
						var overlay_content = parent_obj.children(".mojax_block_overlay_content");
						if(overlay_content.length != 0){
							loaderRefreshDisplay(overlay_content,true);
						}
						else{
							switch (skin) {
								case SKIN_COOL: {
									overlay_content = renderCoolOverlay(LOADER_OVERLAY,parent_obj).css({position:"relative",width:"100%", height:"100%"});
									break;
								}
								case SKIN_OLD: {
									overlay_content = $('<div class="mojax_block_overlay_content"><img src="'+loader_type+'"></div>').css(
										LOADER_OVERLAY_CONTENT_CSS
									);
									break;
								}
							}
							loaderRefreshDisplay(overlay_content,true);
							parent_obj.append(overlay_content);
						}
					},
					hideLoaderOverlay:function(uniq_id){
						var parent_obj = $.getByUniq(uniq_id);
						var overlay_content = parent_obj.children(".mojax_block_overlay_content");
						loaderEndDisplay(overlay_content);
					},

					// TODO перенос коллбэка на ввод пользователя!
					validationCallback:function(item, value){
						console.log('invalid field', value, item);
					}
				};
			}()
		}
	};
	return $mojax;
}();


