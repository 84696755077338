var WIN_CONTENT_CLASS = 'win_text_content';
var WIN_CONTAINER_CLASS = 'mjr-form-container';

var fw_Win_active;

var _FORMS = function () {
	var _FORMS = {
		timestamp: null,
		restaurant_id: null,
		event_rest_id: null,
		eventForm: null,
		controllers: {
			TABLE_BOOKING_CONTROLLER: '/ajax/forms/getTableBooking/',
			PROMO_BOOKING_CONTROLLER: '/ajax/forms/getPromoBooking/',
			AGREE_CONTROLLER: '/ajax/forms/getAgree/',
			OFFER_CONTROLLER: '/ajax/forms/getOffer/',
			POLITICS_CONTROLLER: '/ajax/forms/getPolitics/',
			CAREER_CONTROLLER: '/ajax/forms/getCareer/',
			//2 Расчет кредита
			CREDIT_CALC_CONTROLLER: '/ajax/forms/getCreditCalcForm/',
			//3 Обратная связь
			FEEDBACK_CONTROLLER: '/ajax/forms/getForm/',
			GET_FORM_CONTROLLER: '/ajax/forms/getForm/',
			//4 Индивидуальное предложение
			INDIVIDUAL_CONTROLLER: '/ajax/forms/getIndividualForm/',
			//5 Узнать больше об автомобиле (used)
			USED_CAR_CONTROLLER: '/ajax/forms/getCarUsedInfoForm/',
			//6 Задать вопрос (FAQ)
			FAQ_CONTROLLER: '/ajax/faq/getFaqForm/',
			//Коммент к заказу кулинарии
			COMMENT_ORDER_CONTROLLER:'/ajax/forms/getCommentOrder/',
			EVENT_PAYMENT_CONTROLLER: '/ajax/forms/getPaymentStatus/',
		},
		selectors: {
			FORM_SELECT: 'select.form-control',
			//1 Тест-драйв
			TABLE_BOOKING_SELECTOR: '#table-booking',
			PROMO_BOOKING_SELECTOR: '#promo-booking',
			CAREER_SELECTOR: '#form-career',
			TEST_DRIVE_MODEL_SELECTOR: 'select[name="model"]',
			//3 Обратная связь
			FEEDBACK_SELECTOR: '#feedback_place',
			//4 Индивидуальное предложение
			INDIVIDUAL_SELECTOR: '#individual_place',
			//6 Задать вопрос (FAQ)
			ESTIMATION_SELECTOR: '#estimation_place',
			//
			COMMENT_ORDER_SELECTOR:'#comment-form',
			FW_WIN_SELECTOR: '.fw_win_content',
			EVENT_SELLS_SELECTOR: '#event-sells'
		},
		title: {
			TEST_DRIVE_TITLE: 'Записаться на тест-драйв',
			CREDIT_CALC_TITLE: 'Расчет кредита',
			FEEDBACK_TITLE: 'Обратная связь',
			INDIVIDUAL_TITLE: 'Запросить индивидуальное предложение',
			USED_CAR_TITLE: 'Запрос подробной информации',
			ESTIMATION_TITLE: 'Оцените свой автомобиль',
		},
		immediately: function () {
			$('.open-form').click(function (e) {
				_FORMS.openForm(this);
				fw_MAMMON.preventHref(e);
			})
			$('.testdrive-form').click(function (e) {
				_FORMS.openTestDrive(this);
				fw_MAMMON.preventHref(e);
			});
			$('.credit-form').click(function (e) {
				_FORMS.openCreditCalc();
				fw_MAMMON.preventHref(e);
			});
			$('.feedback-form').click( function(event){
					var link = $(this);
					_FORMS.openFeedBack(link);
					fw_MAMMON.preventHref(event);
			});
			$('.individual-form').click(function (e) {
				_FORMS.openIndividual();
				fw_MAMMON.preventHref(e);
			});
			$('.used-car-form').click(function(e){
				_FORMS.openCarUsedInfoForm();
				fw_MAMMON.preventHref(e);
			});
			$('#comment-form').click(function(e){
				_FORMS.openCommentOrder();
				fw_MAMMON.preventHref(e);
			});
			$('.faq-form').click(function(e){
				_FORMS.openFaqForm();
				fw_MAMMON.preventHref(e);
			});
			if($(_FORMS.selectors.TABLE_BOOKING_SELECTOR).length) {
				_FORMS.loadTableBooking(_FORMS.selectors.TABLE_BOOKING_SELECTOR);
			}
			if($(_FORMS.selectors.PROMO_BOOKING_SELECTOR).length) {
				_FORMS.loadPromoBooking(_FORMS.selectors.PROMO_BOOKING_SELECTOR);
			}
			if($(_FORMS.selectors.FEEDBACK_SELECTOR).length) {
				_FORMS.loadFeedBack(_FORMS.selectors.FEEDBACK_SELECTOR);
			}
			if($(_FORMS.selectors.ESTIMATION_SELECTOR).length) {
				_FORMS.loadPageEstimationForm(_FORMS.selectors.ESTIMATION_SELECTOR);
			}
			if($(_FORMS.selectors.INDIVIDUAL_SELECTOR).length) {
				_FORMS.loadIndividual(_FORMS.selectors.INDIVIDUAL_SELECTOR, false);
			}
			if($(_FORMS.selectors.CAREER_SELECTOR).length) {
				_FORMS.loadCareer(_FORMS.selectors.CAREER_SELECTOR, false);
			}
		},
		openForm: function (link) {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				width: 790,
				offset: 0,
				content: '',
				title: $(link).data('title'),
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadForm(to,link);
		},
		loadForm: function(to,link) {
			fw_MOJAX.send({
				url: _FORMS.controllers.GET_FORM_CONTROLLER,
				data: {code: $(link).data('form')},
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					initFormElements(to);
					// selectCurrentSalon(to,link);
				}
			});
		},
		openMenuModal: function (element) {
			let title = element.dataset.title;
			let image = element.dataset.image;
			let grams = element.dataset.grams;
			let price = element.dataset.price;
			let description = element.dataset.description;

			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				width: 375,
				offset: 0,
				content: `<div class="modal-menu-item">
								<img src="${image}" alt="${title}">
								<div class="wrap">
									<div class="item-name">${title}</div>
									<div class="price-block">
										<span class="gram">${grams}</span>
										<span class="price">${price} ₽</span>
									</div>
									<div class="item-description">${description}</div>
								</div>
							</div>`,
				title: '',
				container_class: WIN_CONTAINER_CLASS + " modal_menu_container",
				content_class: WIN_CONTENT_CLASS + " modal_menu_content",
				without_padding: true
			}, {
			})
		},


		loadTableBooking: function (to) {
			fw_MOJAX.send({
				url: _FORMS.controllers.TABLE_BOOKING_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function (json_data, user_data) {
					_FORMS.getTimes();
					initFormElements(to);
					$(document).on('click', '.j-openDate', function(){
						let dateInput = $(this).closest('form').find('.hasAirpicker');
						if(dateInput.length) {
							dateInput.trigger('focus');
						}
					});
					_FORMS.initTableBookingTimePicker()
				}
			});
		},
		loadEventSellsStatus: function () {
			const paymentId = window.paymentId;
			fw_MOJAX.send({
				url: _FORMS.controllers.EVENT_PAYMENT_CONTROLLER,
				to: _FORMS.selectors.EVENT_SELLS_SELECTOR,
				data: {
					paymentId : paymentId,
					form: this.eventForm
				},
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				user_success: function (json_data, user_data) {
					$('#event-sells').removeClass('col-xl-7 col-lg-10 col-md-12');
					$('#anchor-sells .title').remove();
				}
			});
		},
		loadEventSellsForm: function () {
			let eventId = window.eventId;
			const titleContent = '<div class="title">Купить билеты на мероприятие</div>';
			fw_MOJAX.send({
				to: $('#event-sells'),
				data: {event_id : eventId},
				url : '/ajax/forms/loadEventSellsForm/',
				user_success: function (data) {
					saveToLocalStorage();
					getSavedFormData();
					$('#event-sells').addClass('col-xl-7 col-lg-10 col-md-12');
					$('#anchor-sells').prepend(titleContent);
					initPhoneInput();
					__COMMON.functions().selectEventForm();
					__COMMON.functions().eventInputNumber();
					_FORMS.initAgreeWin();
					_FORMS.initOfferWin();
				}
			})
		},
		initTableBookingTimePicker: function(){
			$(document).on('click', '.time-container-items', function(){
				$('.time-input').prop('readonly', true)
				$(this).toggleClass('-active')
			})

			$(document).on('click', '.time-container-items .custom-timepicker .item', function(){
				$('.time-container-items .custom-timepicker .item').removeClass('-active')
				$(this).addClass('-active')

				$(this).closest('.time-container-items').find('.time-input').val($(this).data('value'))
			})

			$(document).on('click', function (e){
				if($(e.target).closest('.time-container-items').length === 0){
					$('.time-container-items').removeClass('-active')
				}
			})

			$(document).on('click', '.submit-container .button', function(){
				if(!$('.time-input').val()){
					$('.ftype_time').addClass('err')
					$('.time_input_container').addClass('err')
				} else {
					$('.ftype_time').removeClass('err')
					$('.time_input_container').removeClass('err')
				}

				$('.time-input').on('focus', function(){
					$('.ftype_time').removeClass('err')
					$('.time_input_container').removeClass('err')
				})

			})
		},
		loadCareer: function(to) {
			fw_MOJAX.send({
				url : _FORMS.controllers.CAREER_CONTROLLER,
				to : to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function() {
					initFormElements(to);
					_FORMS.initAgreeWin();
				}
			});
		},

		loadPromoBooking: function (to) {
			fw_MOJAX.send({
				url: _FORMS.controllers.PROMO_BOOKING_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function (json_data, user_data) {
					_FORMS.initAgreeWin();
					initSelectForms();
					initInputNumber();
					initPhoneInput();
				}
			});
		},
		initAgreeWin: function () {
			$('.i_agree_personal_data').click(function (e) {
				_FORMS.agreeOpenWin();
				fw_MAMMON.preventHref(e);
			});
			$('.i_politics').click(function (e) {
				_FORMS.politicsOpenWin();
				fw_MAMMON.preventHref(e);
			});
		},
		initOfferWin: function () {
		  $('.public-offer').click(function (e) {
			 _FORMS.offerOpenWin();
			  fw_MAMMON.preventHref(e);
		  });
		},
		agreeOpenWin: function () {
			__COMMON.functions().functionBodyScrollFix();
			__COMMON.functions().functionBodyScrollFix_toggle();

			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				width: 910,
				content: '',
				title: "Согласие на обработку персональных данных",
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS + ' agree-content',
				without_padding: true,
			}, {
				onAfterClose: function() {
					__COMMON.functions().functionBodyScrollFix_toggle();
				},
			})

			fw_MOJAX.send({
				to: fw_Win_active.content,
				url: _FORMS.controllers.AGREE_CONTROLLER,
				overlay_to: fw_Win_active.content,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY
			});
		},
		politicsOpenWin: function () {
			__COMMON.functions().functionBodyScrollFix();
			__COMMON.functions().functionBodyScrollFix_toggle();

			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				width: 910,
				content: '',
				title: "Политика обработки персональных данных",
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS + ' agree-content',
				without_padding: true
			}, {
				onAfterClose: function() {
					__COMMON.functions().functionBodyScrollFix_toggle();
				},
			});
			fw_MOJAX.send({
				to: fw_Win_active.content,
				url: _FORMS.controllers.POLITICS_CONTROLLER,
				overlay_to: fw_Win_active.content,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY
			});
		},
		offerOpenWin: function () {
			__COMMON.functions().functionBodyScrollFix();
			__COMMON.functions().functionBodyScrollFix_toggle();

			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				width: 910,
				content: '',
				title: "Публичная Оферта на оказание услуги по приобретению билетов на мероприятия",
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS + ' agree-content',
				without_padding: true,
			}, {
				onAfterClose: function() {
					__COMMON.functions().functionBodyScrollFix_toggle();
				},
			})

			fw_MOJAX.send({
				data: {rest_id: _FORMS.event_rest_id},
				to: fw_Win_active.content,
				url: _FORMS.controllers.OFFER_CONTROLLER,
				overlay_to: fw_Win_active.content,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY
			});
		},
		selectModelInTestDriveForm: function (model_code,to) {
			var model_select = $(_FORMS.selectors.TEST_DRIVE_MODEL_SELECTOR);
			model_code
				? model_select.ikSelect('select', model_select.find('option[data-model-code="' + model_code + '"]').index(), true)
				: model_select.ikSelect('select', model_select.find('option:first-of-type').index(), true);
			_FORMS.setModelSelectImage(to);
		},
		setModelSelectImage: function (parent) {
			var model_id = $(parent).find('select[name=model] option:selected').data('id');
			$(parent).find('.model_info_item').hide();
			$(parent).find('.model_info_item[data-model-id=' + model_id + '] ').show();
			$(parent).find('input[name=image]').val($('.model_info_item[data-model-id=' + model_id + '] img').attr('src'));
		},
		initModelSelectAction: function (to) {
			$('select[name=model]').change(function () {
				_FORMS.setModelSelectImage(to);
			});
			_FORMS.setModelSelectImage(to);
		},
		initTimePicker: function () {
			var timeRadio = $('input[name=time-radio][type=radio]');
			var timeHidden = $('input[name=time][type=text]');

			timeHidden.val('');
			timeRadio.change(function(e,hasGoto){
				timeHidden.val($(this).val());
				if($(this).closest('.field_wrapper').hasClass('formjor-err'))
					$(this).closest('form').find('button').trigger('click');

				if(hasGoto) {
					var index = $(this).closest('.item').index();
					tnsTime.goTo(index);
				}
			});

			var date = new Date();
			date.setDate(date.getDate() + 1);

			return {date, timeRadio, timeHidden}
		},
		initDatePicker: function (default_date, timePicker, timeHidden) {
			const This = this;
			let dateSelector = 'input[name=date]';
			var inst = $(dateSelector);
			if(!inst || !inst.length) return;
			inst.attr('readonly', true).addClass('hasAirpicker');

			let dateToday = new Date(new Date().setHours(0,0,0,0));
			let todayMark = Date.parse(dateToday);//метка сегодняшней даты
			const twentyFour = 86400000;//миллисекунды в сутках

			let airButtons = [
				{
					content: 'Сегодня',
					//className: 'custom-button-classname',
					onClick: (dp) => {
						let date = dateToday;
						dp.selectDate(date);
						dp.setViewDate(date);
					}
				},
				{
					content: 'Завтра',
					onClick: (dp) => {
						let date = new Date(todayMark + twentyFour);
						dp.selectDate(date);
						dp.setViewDate(date);
					}
				},
				{
					content: 'Послезавтра',
					onClick: (dp) => {
						let date = new Date(todayMark + twentyFour*2);
						dp.selectDate(date);
						dp.setViewDate(date);
					}
				},
			];

			let airPicker = new AirDatepicker(dateSelector,{
				classes: 'air-class',
				autoClose: true,
				buttons: airButtons,
				minDate: new Date(),
				dateFormat(date) {
					return date.toLocaleString('ru', {
						day: 'numeric',
						month: 'long'
					});
				},
				navTitles: {
					days: 'MMMM',
				},
				onSelect: function ({date}) {
					This.timestamp = Date.parse(date);
					This.getTimes();

					//если выбрана сегодняшняя дата, скрыть прошедшее время в timepicker, иначе - показать
					if (!timePicker) return true;
					const current_date = todayMark;
					const selected_date = This.timestamp;

					const yyyy = date.getFullYear();
					let mm = date.getMonth() + 1; // Months start at 0!
					let dd = date.getDate();

					if (dd < 10) dd = '0' + dd;
					if (mm < 10) mm = '0' + mm;

					$('input[name=date_unformatted]').val(dd + '.' + mm + '.' + yyyy);

					if (selected_date === current_date) {
						inst.val("Сегодня, "+inst.val());//нестандартный текст поля
					}
					return true;
				}
			});
			//изначально задать дату
			if (default_date != 'undefined') {
				airPicker.selectDate(default_date);
			}
		},
		getTimes(){
			if(this.timestamp && this.restaurant_id){
				fw_MOJAX.send({
					to: $('.time_input_container'),
					url : '/ajax/forms/getTimes/',
					data : { timestamp: this.timestamp, restaurant_id: this.restaurant_id },
					user_success: () => {
						this.initTimePicker();
					}
				});
			} else {
				$(".time-container-items").html(``);
				$(".time-container-items").addClass('-without-arrow');
				$(".time_input_work_time").remove();
				$(".time-container-info").html(`<div class="f-15">Выберите ресторан и дату</div>`)
			}
		},
		updateFormInfo(value, last_value) {
			const oldPlacesCount = parseInt($('input.form-control.event[type=number]').val(), 10);
			fw_MOJAX.send({
				to: $('#event-sells'),
				url : '/ajax/forms/updateFormInfo/',
				data : {rest_id: value, event_id: last_value},
				user_success: function (data) {
					 localStorage.removeItem('formData');
					 saveToLocalStorage();
			    const newPlacesCount = data.new_places_count;
					initPhoneInput();
					__COMMON.functions().selectEventForm();
					__COMMON.functions().eventInputNumber(oldPlacesCount, newPlacesCount);
					__COMMON.functions().showBeautyDate();
					__COMMON.functions().initEventName();
					_FORMS.initAgreeWin();
					_FORMS.initOfferWin();
				}
			})
		},
		openCreditCalc: function () {
			fw_Win_active = fw_WIN.create({
				lock_page: true,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.CREDIT_CALC_TITLE,
				container_class: "",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadCreditCalc(to);
		},
		loadCreditCalc: function (to) {
			fw_MOJAX.send({
				url : _FORMS.controllers.CREDIT_CALC_CONTROLLER,
				to : to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function() {
					_CREDIC_CALC.initCreditCalc({calcButtonSelectText: '#calc_credit_button'});
					$(to).find('input[name=model]').val(model_name);
					$(to).find('input[name=integration]').val(model_name);
					$(to).find('input[name=price]').val(price);
					$(to).find('.credit_form_car_info .car_name').text(model_name);
					$(to).find('.credit_form_car_info .car_info_credit_price .numeral').html(text_price);
					$(to).find('.credit_form_car_info .car_info_image').attr('src', model_image);
				}
			});
		},
		openFeedBack: function (link) {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				width: 790,
				offset: 0,
				content: '',
				title: _FORMS.title.FEEDBACK_TITLE,
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadFeedBack(to,link);
		},
		loadFeedBack: function(to,link) {
			fw_MOJAX.send({
				url: _FORMS.controllers.FEEDBACK_CONTROLLER,
				data: {form: link.data('form')},
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					initFormElements(to);
					selectCurrentSalon(to,link);
				}
			});
		},
		//коммент к заказу
		openCommentOrder: function (link) {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				width: 800,
				offset: 0,
				content: '',
				title: '',
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadCommentOrder(to,link);
		},
		loadCommentOrder: function(to,link) {
			fw_MOJAX.send({
				url: _FORMS.controllers.COMMENT_ORDER_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					initInputNumber();
					initPhoneInput();
					_FORMS.initAgreeWin();
				}
			});
		},
		//
		openIndividual: function() {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.INDIVIDUAL_TITLE,
				container_class: "",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			},{
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadIndividual(to, true);
		},
		loadIndividual: function(to, isForm) {
			fw_MOJAX.send({
				url: _FORMS.controllers.INDIVIDUAL_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					if(isForm){
						putIndividualInfo(_FORMS.selectors.FW_WIN_SELECTOR);
						randomOidTerritory(_FORMS.selectors.FW_WIN_SELECTOR);
					} else {
						randomOidTerritory(_FORMS.selectors.INDIVIDUAL_SELECTOR);
						$(_FORMS.selectors.INDIVIDUAL_SELECTOR + ' .form_inner_wrapper').prepend('<h2>Запросить индивидуальное предложение</h2>');
						$('.individual_form_info ').hide();
						_FORMS.initModelSelectAction();
					}
				}
			});
		},
		openCarUsedInfoForm: function() {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.USED_CAR_TITLE,
				container_class: "",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadCarUsedInfoForm(to);
		},
		loadCarUsedInfoForm: function(to) {
			fw_MOJAX.send({
				url : _FORMS.controllers.USED_CAR_CONTROLLER,
				to : to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function() {
					initGetCarInfoForm(to);
				}
			})
		},
		openFaqForm: function () {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				width: 790,
				title: "Задать вопрос",
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadFaqForm(to);
		},
		loadFaqForm: function (to) {
			fw_MOJAX.send({
				url: _FORMS.controllers.FAQ_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					initFormElements(to);
				}
			});
		},
		loadPageEstimationForm: function(to) {
			fw_MOJAX.send({
				url : '/ajax/forms/getEstimationForm/',
				to : to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function(){
					$(_FORMS.selectors.ESTIMATION_SELECTOR).find('.title').text(_FORMS.title.ESTIMATION_TITLE);
					_FORMS.loadEstimationModels();
				}
			});
		},
		loadEstimationModels: function(){
			fw_MOJAX.send( {
				url : "/ajax/cars/getBrandsListForSelect/",
				data: {},
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				user_success: function(json_data) {
					$('select[name=brand]')[0].selectize.on('change', function (brand) {
						!brand ? clearSelect('select[name=model]') : _FORMS.changeModels(brand);
					})
				}
			});
		},
		changeModels: function(brand) {
			fw_MOJAX.send( {
				url : "/ajax/cars/getModelsListForSelect/",
				data: {"brand": brand},
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				user_success: function(json_data) {
				}
			});
		},
		openWorkTime(json_data){
			const data = JSON.parse(json_data);
			const div = $('<div class="workingSchedule"></div>');
			data.forEach(item => {
				const el = $(`<div class="item">${item.day} ${item.from} - ${item.to}</div>`);
				div.append(el)
			});
			fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: div,
				width: 322,
				title: "<span class='workingScheduleTtl'>График работы</span>",
				container_class: WIN_CONTAINER_CLASS,
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
		},
	};
	_FORMS.immediately();
	return _FORMS;
}();

function initGetCarInfoForm(parent){
	$(parent).find('input[name=model_name]').val(model_name);
	$(parent).find('input[name=car]').val(model_short_name);
	$(parent).find('input[name=price_clear]').val(price_clear);
	$(parent).find('input[name=TextYear]').val(year);
	$(parent).find('input[name=TextRun]').val(run);
	$(parent).find('input[name=territory_oid]').val(territory_oid);

	$(parent).find('.form-car-info-used .car-name').text(model_name);
	$(parent).find('.form-car-info-used .car-price').html(text_price);
	$(parent).find('.form-car-info-used .car-image').attr('src', model_img_src);
}
function selectCurrentSalon(parent, link){
	if (link) {
		var salon_id = link.data('salon-id');
		if (!(salon_id==''||salon_id==undefined)){
			if ($(parent).find('select[name=territory_oid] option[value='+salon_id+']').length !=0){
				$(parent).find('select[name=territory_oid]').val(salon_id);
				$(parent).find('select[name=territory_oid]').ikSelect('reset').ikSelect('redraw');
			}
		}
	}
}
function putIndividualInfo(parent){
	$(parent).find('input[name=TextBrand]').val(brand_name_individ);
	$(parent).find('input[name=model_name]').val(model_name_simple_individ);
	$(parent).find('input[name=model_price]').val(price_individ);
	$(parent).find('input[name=order_num]').val(order_num_individ);
	$(parent).find('input[name=url]').val(location.href);
	$(parent).find('select[name=model] option:selected').removeAttr('selected');
	//TODO: проверить будет ли работать selected
	$(parent).find('select[name=model] option[data-model_id=' + model_id_individ + ']').attr('selected','selected');
	$(parent).find('select[name=model]').parents('.field_wrapper').hide();
	$(parent).find('.individual_form_info .car-name').text(model_name_individ);
	$(parent).find('.individual_form_info .car-price').html(text_price_individ);
	$(parent).find('.individual_form_info .car-image').attr('src', model_big_image_individ);
}
function randomOidTerritory(parent) {
	var countTerritory = $(parent).find('select[name=territory_oid] option').length;
	if(countTerritory > 1) {
		var rand = Math.floor(Math.random() * countTerritory);
		$(parent).find('select[name=territory_oid] option:selected').removeAttr('selected');
		var val =  $(parent).find('select[name=territory_oid] option').eq(rand).val();
		$(parent).find('select[name=territory_oid]').val(val);
		$(parent).find('select[name=territory_oid]').ikSelect('reset').ikSelect('redraw');
	}
	else{
		$(parent).find('select[name=territory_oid]').parents('.field_wrapper ').hide();
	}
}


function initFormElements(to) {
	_FORMS.initAgreeWin();
	initSelectForms();
	initInputNumber();
	initPhoneInput();
	initInputFile(to);
	const {date, timeRadio, timeHidden} = _FORMS.initTimePicker();
	_FORMS.initDatePicker(date, timeRadio, timeHidden);
}


function initSelectForms() {
	var $select = $('select.form-control');
	$select.select2({
		minimumResultsForSearch: Infinity
	});

	$select.select2().on('change.select2', function(e) {
		const value = e.target.value;
		if(value)
			$(this).addClass('-selected');
		else
			$(this).removeClass('-selected');

		switch (e.target.name){
			case 'restaurant':
				_FORMS.restaurant_id = value;
				_FORMS.getTimes();
				break;
		}
	});

	if($("#table-booking").data('restaurant')) {
		let res_id = $("#table-booking").data('restaurant');
		$($select).val(res_id).trigger('change');
	}
}

function initInputNumber() {
	$input = $('input.form-control[type=number]');
	$input.attr('readonly', true);
	$input.spinner({
		min: 1,
		step: 1,
	});
}

function initPhoneInput() {
	maskPhone('input[name=phone], input[autocomplete=tel], input[type=phone]');
}

function initInputFile(wrapper) {
	var $inputFile = $(wrapper).find('input[type="file"]');
	$inputFile.wrap("<label class='file-upload'></label>");
	var $label = $inputFile.closest('label');
	$label.prepend("<mark data-default='Прикрепить файлы'>Прикрепить файлы</mark>");
	stylingInputFile($label);

	$inputFile.closest('.field_wrapper').removeClass('formjor-err');//из-за изменений разметки данного поля происходит его проверка на заполненность, а она не нужна при раскрытии страницы

	function stylingInputFile(wrapper) {
		var inp = wrapper.find("input"),
			btn = wrapper.find(".file-upload__button"),
			lbl = wrapper.find("mark");

		// Crutches for the :focus style:
		inp.focus(function () {
			wrapper.addClass("focus");
		}).blur(function () {
			wrapper.removeClass("focus");
		});

		var file_api = (window.File && window.FileReader && window.FileList && window.Blob) ? true : false;

		inp.change(function () {
			var file_name;
			if (file_api && inp[0].files[0])
				file_name = inp[0].files[0].name;
			else
				file_name = inp.val().replace("C:\\fakepath\\", '');


			if (!file_name.length) {
				setDefault();
				return;
			}

			if (lbl.is(":visible")) {
				lbl.text(file_name);
				btn.text("Выбрать");
			} else {
				btn.text(file_name);
			}
			wrapper.addClass('-active');

		}).change();

		//после отправки формы, если нужно обнулить поля формы:
		setDefault();

		function setDefault() {
			wrapper.removeClass('-active');
			lbl.text(lbl.attr('data-default'));
		}
	}
}

function saveToLocalStorage() {
	$('#submitBtn').click(function(e) {
        const eventId = window.eventId,
		      form = $(this).closest('form'),
		      formDataArray = [],
		      formData = form.serializeArray();

		let formDataObj = {};
		$.each(formData, function(_, kv) {
			formDataObj[kv.name] = kv.value;
		});
		_FORMS.eventForm = formDataObj;

		// Обработка disabled полей (когда один ресторан)
		form.find(':disabled').each(function() {
			const name = this.name;
			const value = $(this).val();
			$(this).removeAttr('disabled');

			formData.push({ name: name, value: value });
			formDataObj[name] = value;

			$(this).attr('disabled', 'disabled');
		});
		let cookieData = {
			event: eventId
		};
		formData.forEach((item) => {
			formDataArray.push(item);
			switch (item.name) {
				case 'clients':
					cookieData.clients = item.value;
					break;
				case 'restaurant':
					cookieData.restaurant = item.value;
					break;
			}
		});
		setCookie('eventData', JSON.stringify(cookieData), 1);
		localStorage.setItem('formData', JSON.stringify(formDataArray));
	});
}

function getSavedFormData() {
	const savedFormData = localStorage.getItem('formData');
	let cookie = getCookie('eventData')
	if (savedFormData && cookie && cookie.length) {
		$('.form-block .field_wrapper .ftype_tel input').css('color', 'black');
		const formDataArray = JSON.parse(savedFormData);
		const form = document.querySelector('.form-event-sells');

		formDataArray.forEach(item => {
			const fieldName = item.name;
			const fieldValue = item.value;
			const formField = form?.querySelector(`[name="${fieldName}"]`);
			if (formField) {
				formField.value = fieldValue;

				if (formField.tagName === 'SELECT' && fieldName === 'restaurant') {
					const select2Container = formField.nextElementSibling.querySelector('.select2-selection__rendered');
					if (select2Container) {
						const selectedOption = formField.querySelector(`option[value="${fieldValue}"]`);
						if (selectedOption) {
							select2Container.textContent = selectedOption.textContent;
						}
					}

					const optionExists = Array.from(formField.options).some(option => option.value === fieldValue);
					if (!optionExists && formField.options.length > 0) {
						formField.value = formField.options[0].value;
						$(formField).trigger('change.select2');
					}
				}
			}
		});
	}
}

function setCookie(name, value, days) {
	const d = new Date();
	d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
	const expires = "expires=" + d.toUTCString();
	document.cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
}

function getCookie(name) {
	const cookies = document.cookie.split('; ');
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		if (cookie.startsWith(name + '=')) {
			return cookie.substring(name.length + 1);
		}
	}
	return null;
}

function returnToEventSellsForm() {
	$(document).on('click', '#return-button', function() {
		_FORMS.loadEventSellsForm();
	});
}

$(document).ready(function() {
	saveToLocalStorage();
	getSavedFormData();
	returnToEventSellsForm();
});

